@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
}

/* use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}

ul,
ol {
  list-style: disc;
}

a {
  color: #2B547E;
}

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
}
